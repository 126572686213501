<template>
    <van-tabbar v-model="active2" :route="true" active-color="#07c160" inactive-color="#000" safe-area-inset-bottom style="z-index:999">
        <van-tabbar-item icon="home-o" to="/home">首页</van-tabbar-item>
        <van-tabbar-item icon="gold-coin-o" to="/agent">推广中心</van-tabbar-item>
        <van-tabbar-item icon="contact" to="/mycenter">个人中心</van-tabbar-item>
        <van-tabbar-item icon="contact" to="/myagent">我的收益</van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    name: "AgentTabber",
    props: ["active"],
    data: () => ({
        active2: "",
    }),
    created() {
        this.active2 = this.active;
    },
};
</script>
