<template>
    <div class="agent-index">
        <van-tabs v-model="active" :line-width="60" title-inactive-color="#999" title-active-color="#353334" color="#EC2627">
            <van-tab title="销量高"></van-tab>
            <van-tab title="佣金高"></van-tab>
            <van-tab title="易出单"></van-tab>
            <van-tab title="最新抢购"></van-tab>
        </van-tabs>

        <van-card
            v-for="item in agentGoods"
            :key="item.id"
            :price="item.price"
            :title="item.title"
            :thumb="item.thumb_pic"
            :desc="item.share_title"
            @click="href(item.id)"
            :lazy-load="true"
        >   
            <template #origin-price>
                ¥{{ item.price_show }}
            </template>
            <template #bottom>
                <div class="bottom">
                    <span>
                        <van-icon name="friends-o" size="17"/>{{ item.buy_num }}人已推广
                    </span>
                    <van-button size="mini" color="#EC2627">赚¥ {{ item.fx_price_1 }}</van-button>
                </div>
            </template>
        </van-card>

        <AgentTabber active="1" />
    </div>
</template>

<script>
import AgentTabber from "@/components/tabbar/AgentTabber";

export default {
    name: "AgentIndex",
    data() {
        return {
            active: 0,
            agentGoods: []
        };
    },
    components: {
        AgentTabber,
    },
    created() {
        this.getPageData();
    },
    methods: {
        async getPageData(type = 1) {
            const res = await axios.get("/get_agent_goods", {
                params: {
                    type
                }
            });
            this.agentGoods = res.data;
        },
        href(id){
             window._czc.push(['_trackEvent','推广中心点击商品', '商品id','',id])
            this.$router.push({path:`/detail/${id}`})
        }
    },
    watch: {
        active(newVal) {
            this.getPageData(newVal + 1);
        }
    }
};
</script>

<style scoped lang="less">
.agent-index {
    padding-bottom: 50px;
    box-sizing: border-box;
}
.van-card {
    background: none;
    padding: 10px;
    margin: 0;

    .van-card__thumb {
        width: 137px;
        height: 139px;

        /deep/ img {
            border-radius: 5px;
            object-fit: fill !important;
            box-shadow: 0px 2px 7px 2px rgba(246, 246, 246, 0.73);
        }
    }
    .van-card__title {
        color: #232323;
        font-size: 14px;
    }
    .van-card__price {
        color: #EC2627;
        font-weight: bold;
        font-size: 10px;
    }
    .van-card__price-currency {
        font-size: 9px;
    }
    .van-card__price-integer {
        font-size: 17px;
    }
    .van-card__desc {
        color: #666;
        font-size: 13px;
        margin-top: 2px;
    }
    .van-card__origin-price {
        font-size: 12px;
        color: #ccc;
    }
    .van-card__origin-price {
        float: right;
    }
    .van-card__bottom .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .bottom .van-button {
        font-size: 12px;
    }
    .bottom > span {
        color: #999;
        font-size: 12px;
        .van-icon {
            vertical-align: -3px;
        }
    }
}
</style>
